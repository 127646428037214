<template>
  <div>
    <defaultFieldTypes
      :field="field"
      :fieldAttributes="{
        ...fieldAttributes,
        options: options,
        type: 'select',
      }"
      v-on="$listeners"
      :templateContent="result"
      :value="value"
    ></defaultFieldTypes>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  props: ["result", "fieldAttributes", "field", "value"],
  data() {
    return { options: [] };
  },
  watch: {
    "result.valueLine.packetType": function (newVal) {
      console.log("new value packet type");
      this.getInboundConnectionList(newVal);
    },
  },

  created() {
    console.log(this.result.valueLine.packetType);
    let packetType = this.result.valueLine.packetType;
    if (typeof packetType != "undefined" && packetType != "") {
      this.getInboundConnectionList(packetType);
    }
  },
  methods: {
    getInboundConnectionList(packetType) {
      let caller = this;
      caller.frameworkAxiosRequest({
        method: 'post',
        url: 'serve.php?f=testing&f2=configureAndRunTests',
        data: {
          function: 'getExpectClientServer',
          type: packetType,
        },
      })
          .then(function (response) {
          console.log(response);
          // templateContent.fields.connectionId.associatedOption =
          let connectionsArray = response.data.result.json["connectionsArray"];
          ////console.log(response.data.result.json.testcaseList);

          caller.options = connectionsArray;
        })
        .catch(function () {
          //handle error
        });
    },
  },
};
</script>